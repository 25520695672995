import React, {useState, useEffect, Component } from "react";
//import './index.css';
import { observer } from "mobx-react";
import { action, observable, makeObservable} from "mobx";
import { RouterStore, syncHistoryWithStore } from 'mobx-react-router';
import { Typography } from "@material-ui/core";
import Paper from '@material-ui/core/Paper';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Posicao from "./pages/posicao";
import Trades from "./pages/trades";
import CurrentStateService from "./service/CurrentStateService";
import Session from "./Session"
import Main from "./main"
import Login from "./login"
import CssBaseline from '@mui/material/CssBaseline';
import SettingsIcon from '@mui/icons-material/Settings';
import Settings from "./pages/settings";
import IconButton from '@mui/material/IconButton';
import { grey } from '@mui/material/colors';
import Tooltip from '@mui/material/Tooltip';


@observer
class App extends Component {

  @observable sessionStore = new Session();
  @observable settings = false;
  @observable ativoAtual = 'HASH11';

  constructor(props) {
    super(props)
    makeObservable(this)
  }

  @action
  logout() {
    this.sessionStore.logout();
    this.forceUpdate();
  }

  @action
  openSettings() {
    this.settings = true;
  }

  @action
  closeSettings() {
    this.settings = false;
  }

  @action
  atualizarAtivoAtual(novo) {
    this.ativoAtual = novo;
    this.update();
  }

  update() {
    this.forceUpdate();
  }
//className="App" style={{overflow: 'scroll'}}
  render() {
    return (
      <div>          
          <AppBar position="static">
            <Toolbar>
            <Box sx={{ flexGrow: 1}}>              
              <Typography style={{ margin: 10, maxWidth: '75%' }}>
                MT5 - Pythonbot
              </Typography>
            </Box>
              { this.sessionStore.sessionkey !== '' ?
                <div>
                  <Tooltip title="Configurações" arrow>
                    <IconButton onClick={this.openSettings.bind(this)}>
                      <SettingsIcon sx={{ color: grey[100] }}   />
                    </IconButton>
                  </Tooltip>
                  <Button variant="contained" color="secondary" onClick={this.logout.bind(this)}>Sair</Button>
                </div>
                :<div></div>
              }
            </Toolbar>
          </AppBar>
            <div style={{ maxWidth: '98%', margin: 15, backgroundColor: '#e5e5e5', paddingBottom: "15px" }}>
          { this.sessionStore.sessionkey !== '' ?
              <Paper elevation={1}>              
                { this.settings ? 
                <Settings sessionStore = {this.sessionStore} close={this.closeSettings.bind(this)}  ativoAtual={this.ativoAtual} />
                :<Main sessionStore = {this.sessionStore} atualizarAtivoAtualSettings={this.atualizarAtivoAtual.bind(this)} openSettings={this.openSettings.bind(this)}/>
              }              
              </Paper>
              :
              <div> 
                <Login sessionStore = {this.sessionStore} update = {this.update.bind(this)} />
              </div>
              }
            </div>
      </div>
    )
  }
}

export default App;

