import React, { Component } from 'react';
import { Card, CardContent, CardHeader } from '@material-ui/core';
import { observer } from 'mobx-react';
import { action, observable, makeObservable } from 'mobx';
import CurrentStateService from '../service/CurrentStateService';

interface ICapturaProps {
    sessionStore?: any;
    posicao?: any;
}

interface ICapturaState {
}

@observer
class Captura extends Component<ICapturaProps, ICapturaState> {

    @observable estadomt5 = {}

    service = new CurrentStateService();

    constructor(props) {
        super(props);
        makeObservable(this);
      }

    async componentDidMount() {
        this.atualizarStatus()
    }

    componentDidUpdate() {
        
    }

    @action
    async atualizarStatus() {
        this.service.configAuth(this.props.sessionStore.sessionkey);
        this.estadomt5 = await this.service.mt5Status()
    }

    toBase64Str(data) {
        let res = ""
        let c =0
        //hack pois a imagem esta como b'base64data'
        for (c=2; c < data.length -1; c++) {
            res += String.fromCharCode(data[c])
        }
        return res
    }
   

    render() {
        return (
            <div>
                <div style={{
                    display: 'flex',   
                    flexFlow: 'row wrap',                    
                    margin: 5,                    
                    marginLeft: "25px",
                    marginTop: "15px",
                    marginRight:"20px",
                    paddingBottom: "15px"
                }}>
                    <Card style={{backgroundColor: "#f9f9f9", maxWidth: "100%"}}>
                        <CardHeader title="CAPTURA" />
                        <CardContent>
                            Conectado: {this.estadomt5["connected"]}
                            <br/>
                            Hora: {this.estadomt5["timestamp"]}
                            <br/>
                        {this.props.posicao && this.props.posicao.captura_tela ? 
                            <img style={{maxWidth:'100%', height:'auto'}} src ={"data:image/png;base64,"+this.toBase64Str(this.props.posicao.captura_tela.data)}/>
                            :<div><br/></div>       
                        }
                        </CardContent>
                    </Card>
                </div>
            </div>
        )
    }    

}

export default Captura;
