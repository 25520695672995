import React, { Component } from 'react';
import { Button, Card, CardContent, CardHeader, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { Typography } from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';
import PowerIcon from '@mui/icons-material/Power';
import PowerOffIcon from '@mui/icons-material/PowerOff';
import RefreshIcon from '@mui/icons-material/Refresh';
import Tooltip from '@mui/material/Tooltip';
import { observer } from "mobx-react";
import { action, observable, makeObservable } from "mobx";
import CurrentStateService from '../service/CurrentStateService';

interface IResultadosProps {
  sessionStore?: any;
  resultados?: any;
  atualizarAtivo?: any;
}

interface IResultadosState {
}

@observer
class Resultados extends Component<IResultadosProps, IResultadosState> {

  service = new CurrentStateService();

  //@observable resultados = []
  @observable aguardandoAcao = false  

  timer? = undefined

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  @action
  atualizarAtivo = ativo => () => {
    this.props.atualizarAtivo(ativo);
  }

  @action
  componentDidMount() {
  }

  @action
  zerar = ativo => () => {
    if (confirm('Deseja realmente zerar as operações no ativo: ' + ativo + '?')) {
      this.service.configAuth(this.props.sessionStore.sessionkey);
      this.service.newAcao(ativo, 'zerar').then((res) => {      
        this.aguardandoAcao = true;
        this.timer = setInterval(() => {
          this.service.getAcoes(ativo).then((res) => {
            var acao = res
            if (acao.executada != false) {
              clearInterval(this.timer)
              this.aguardandoAcao = false
            }
          })
        }, 1000);
      }).catch((e) => {
        console.log(e);
      })
    }
  }

  @action
  iniciar = ativo => () => {
    if (confirm('Iniciar as operações no ativo: ' + ativo + '?')) {
      this.service.configAuth(this.props.sessionStore.sessionkey);
      this.service.newAcao(ativo, 'ligar').then((res) => {      
        this.aguardandoAcao = true;
        this.timer = setInterval(() => {
          this.service.getAcoes(ativo).then((res) => {
            var acao = res
            if (acao.executada != false) {
              clearInterval(this.timer)
              this.aguardandoAcao = false
            }
          })
        }, 1000);
      }).catch((e) => {
        console.log(e);
      })
    }
  }

  @action
  parar = ativo => () => {
    if (confirm('Deseja realmente parar as operações do robo no ativo: ' + ativo + '?')) {
      this.service.configAuth(this.props.sessionStore.sessionkey);
      this.service.newAcao(ativo, 'parar').then((res) => {      
        this.aguardandoAcao = true;
        this.timer = setInterval(() => {
          this.service.getAcoes(ativo).then((res) => {
            var acao = res
            if (acao.executada != false) {
              clearInterval(this.timer)
              this.aguardandoAcao = false
            }
          })
        }, 1000);
      }).catch((e) => {
        console.log(e);
      })
    }
  }

  @action
  atualizar = ativo => () => {
    this.service.configAuth(this.props.sessionStore.sessionkey);
    this.service.newAcao(ativo, 'atualizar').then((res) => { 
      this.aguardandoAcao = true;
        this.timer = setInterval(() => {
          this.service.getAcoes(ativo).then((res) => {
            var acao = res
            if (acao.executada != false) {
              clearInterval(this.timer)
              this.aguardandoAcao = false
            }
          })
        }, 1000);
    }).catch((e) => {
      console.log(e);
    })
  }

  formatarNumero(valor) {
    if (valor == undefined)
      valor = 0.0;
    var v = parseFloat(valor).toFixed(2);
    return v.replace(".", ",")
  }
  
//                            <Tooltip title="Zerar" arrow>
//                             {this.aguardandoAcao ?<CloseIcon color="disabled" /> :
//                              <CloseIcon onClick={this.zerar(row.ativo)} />}
//                            </Tooltip>
  render() {
    return (
      <Card style={{backgroundColor: "#f9f9f9", flexGrow: 4, marginRight: "20px"}}>
          <CardHeader title="RESULTADOS"/>
          <CardContent>
            <TableContainer>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Ativo</TableCell>              
                    <TableCell align="right">Resultado Diário</TableCell>              
                    <TableCell align="center">Ações</TableCell>
                  </TableRow>
                </TableHead>
                {this.props.resultados && this.props.resultados.length > 0 ?
                  <TableBody>
                    {this.props.resultados.map((row) => (
                      <TableRow
                        key={row.ativo}
                      >
                        <TableCell component="th" scope="row">
                          {row.ativo}
                        </TableCell>
                        <TableCell align="right">R$ {this.formatarNumero(row.resultadoDiario)}</TableCell>
                        <TableCell align="center">
                          <div style={{gap: '20px', flexFlow: 'row', flexWrap: 'wrap',display: 'flex', justifyContent:"center"}}>
                            <Tooltip title="Iniciar" arrow>
                              {this.aguardandoAcao ?<PowerIcon color="disabled" /> :
                              <PowerIcon onClick={this.iniciar(row.ativo)} />}                            
                            </Tooltip>
                            <Tooltip title="Parar" arrow>
                            {this.aguardandoAcao ?<PowerOffIcon color="disabled" /> :
                              <PowerOffIcon onClick={this.parar(row.ativo)} />}                            
                            </Tooltip>
                            <Tooltip title="Atualizar Ativo" arrow>
                            {this.aguardandoAcao ?<RefreshIcon color="disabled" /> :
                              <RefreshIcon onClick={this.atualizar(row.ativo)} />}                            
                            </Tooltip>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                  :
                  <TableBody>
                    <TableRow
                    >
                      <TableCell component="th" scope="row">
                      </TableCell>
                      <TableCell align="right"></TableCell>
                    </TableRow>
                  </TableBody>
                }
              </Table>
            </TableContainer>
          </CardContent>
      </Card>
    )
  }
}

export default Resultados;
