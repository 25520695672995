
class StorageUtil {

    static setItem(store, json) {
        if (json) {
            localStorage.setItem("GH-" + store, json);
        } else {
            StorageUtil.removeItem(store);
        }
    }
 
    static getItem(store) {
        return localStorage.getItem("GH-" + store);
    }
 
    static getObject(store) {
        return JSON.parse(StorageUtil.getItem(store));
    }
 
    static removeItem(store) {
        localStorage.removeItem("GH-" + store);
    }
 
 }
 
 export default StorageUtil;
 