import React, { Component } from 'react';
import { Card, CardContent, CardHeader, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { Typography } from '@material-ui/core';
import { makeObservable } from 'mobx';


class Trades extends Component {

    componentDidMount() {
        console.log(this.props.trades);        
    }

    estadoTradeDesc(numEstado) {
      var desc = ""
      switch(numEstado) {
        case 1:
          desc = "Aguardando Entrada"
          break;
        case 2:
          desc = "Aguardando Saída"
          break;
        case 3:
          desc = "Trade Finalizado"
          break;
      }
      return desc
    }

    formatarNumero(valor) {
      if (valor == undefined)
        valor = 0.0;
      var v = parseFloat(valor).toFixed(2);
      return v.replace(".", ",")
    }

    precoSaida(precoEntrada, lote, loteSaida) {
      if (precoEntrada == undefined)
        return  this.formatarNumero(0.0);
      var precoSaida = (Math.ceil(((lote*precoEntrada)/loteSaida)*100.0) / 100.0) + 0.01
      return this.formatarNumero(precoSaida)
    }

    
    render() {
        return (
          <Card style={{marginLeft: "21px", marginTop: "15px",  backgroundColor: "#f9f9f9", marginRight:"20px"}}>
            <CardHeader title={"TRADES - " + this.props.ativoAtual}/>
            <CardContent>
                <TableContainer>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Entrada</TableCell>
                        <TableCell align="right">Estado</TableCell>
                        <TableCell align="right">Lote Entrada</TableCell>
                        <TableCell align="right">Lote Saida</TableCell>
                        <TableCell align="right">Preço Entrada</TableCell>
                        <TableCell align="right">Preço Saida</TableCell>                        
                      </TableRow>
                    </TableHead>
                    {this.props.trades && this.props.trades.length > 0 ?
                    <TableBody>
                      {this.props.trades.map((row) => (
                        <TableRow
                        key={row.entrada}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell component="th" scope="row">
                            R$ {this.formatarNumero(row.entrada)}
                          </TableCell>
                          <TableCell align="right">{this.estadoTradeDesc(row.estado_trade)}</TableCell>
                          <TableCell align="right">{row.lote_entrada} cotas</TableCell>
                          <TableCell align="right">{row.lote_saida} cotas</TableCell>
                          <TableCell align="right">R$ {this.formatarNumero(row.preco_entrada)}</TableCell>
                          <TableCell align="right">R$ {this.precoSaida(row.preco_entrada, row.lote_entrada, row.lote_saida)}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                    :
                    <TableBody>
                        <TableRow
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                          <TableCell component="th" scope="row">
                          </TableCell>
                          <TableCell align="right"></TableCell>
                          <TableCell align="right"></TableCell>
                          <TableCell align="right"></TableCell>
                          <TableCell align="right"></TableCell>
                          <TableCell align="right"></TableCell>
                        </TableRow>
                    </TableBody>
                    }
                  </Table>
                </TableContainer>
            </CardContent>            
          </Card>
        )
    }
}

export default Trades;
