import { createRoot } from 'react-dom/client';
import App from './App';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
//import './index.css';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

const theme = createTheme({
    palette: {
      primary: {
        main: '#1a237e',
      },
      secondary: {
        main: '#00bcd4',
      },
    },
  });


const container = document.getElementById('GHApp');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
        <ThemeProvider theme={theme}>
          <Router>            
                <CssBaseline />
                <Routes>
                  <Route path="/" element={<App />} />
                </Routes>
          </Router>
        </ThemeProvider>
);
