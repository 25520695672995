import { observable } from 'mobx';
import StorageUtil from './utils/StorageUtils';

class Session {
    @observable sessionkey = '';
    @observable expires = '';
    static nomeStorage = "UserStore";

    constructor() {
        let storage = StorageUtil.getObject(Session.nomeStorage);
        if (storage) {
            for (var key in storage) {
                this[key] = storage[key];
            }
        }
    }

    login(session) {
        let loginSession = {
            sessionkey: session
        }
        StorageUtil.setItem(Session.nomeStorage, JSON.stringify(loginSession));
        this.sessionkey = session;
    }

    static clearStorage() {
        let loginSession = {
            sessionkey: ''
        }
        StorageUtil.setItem(Session.nomeStorage, JSON.stringify(loginSession));
    }

    logout() {
        let loginSession = {
            sessionkey: ''
        }
        StorageUtil.setItem(Session.nomeStorage, JSON.stringify(loginSession));
        this.sessionkey = '';
    }
}

export default Session;