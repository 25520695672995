import React, {useState, useEffect, Component } from "react";
import { observer } from "mobx-react";
import { action, computed, observable, makeObservable } from "mobx";
import { Button, TextField} from "@material-ui/core";
import Paper from '@material-ui/core/Paper';
import CurrentStateService from "./service/CurrentStateService";

const paperStyle = { height: '70%', width: '30%', margin: 20, textAlign: 'center', display: 'inline-block'};

interface ILoginProps {
    sessionStore?: any;
    update?: any;    
  }
  
  interface ILoginState {
  }

@observer
class Login extends Component<ILoginProps, ILoginState> {

    service = new CurrentStateService();
    @observable user = '';
    @observable passwd = '';

    constructor(props) {
        super(props);
        makeObservable(this);
    }
       

    @action
    async dologin (sessionStore: any) {
        let session = await this.service.login(this.user, this.passwd);
        console.log(this.props.sessionStore);
        console.log(session);
        sessionStore.login(session.sessionkey);
        this.service.configAuth(session);
        this.props.update();
    };

    componentDidMount() {
    }

    handleTextFieldKeyDown = (event: any) => {
        return true;
    }

    @action handleChangePasswd = (event: any) => {
        this.passwd = event.target.value;
    }

    @action handleChangeUser = (event: any) => {
        this.user = event.target.value;
    }

    @action clearFields = () => {
        this.user = '';
        this.passwd = '';
    }

    inputValid = () => {
        return this.passwd != '' && this.user != ''
    }
    //elevation={20}
    //value={this.user}
    //value={this.passwd}
    render() {
        return (
            <div style={{ textAlign: 'center' }}>
                <Paper style={{ height: '70%', minWidth:'260px', width: '30%', margin: 20, textAlign: 'center', display: 'inline-block'}} elevation={8} > 
                    <div style={{ height: '100%', width: '100%', paddingTop: '20px' }}>
                        <TextField
                            label="User"
                            onChange={this.handleChangeUser.bind(this)}
                            onKeyDown={this.handleTextFieldKeyDown.bind(this)}
                            style={{ width: '50%' }}
                            autoFocus={true}
                        />
                    </div>
                    <div style={{ height: '100%', width: '100%', paddingTop: '20px' }}>
                        <TextField
                            type="password"
                            label="Password"
                            error={this.passwd === ''}
                            onChange={this.handleChangePasswd.bind(this)}
                            onKeyDown={this.handleTextFieldKeyDown.bind(this)}
                            style={{ width: '50%' }}
                        />
                    </div>
                    <div style={{ display: 'flow', paddingTop: '30px', paddingBottom: '20px' }}>
                        <Button variant="contained"
                            color="primary"                            
                            onClick={this.dologin.bind(this, this.props.sessionStore)}
                            title="Login"
                            disabled={!this.inputValid.bind(this)}
                        >
                            Entrar
                        </Button>
                    </div>
                </Paper>
            </div>

        )
    }
}

/**/

export default Login;
