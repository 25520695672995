import React, { Component } from 'react';
import { Card, CardContent, CardHeader } from '@material-ui/core';
import Resultados from './resultados';
import { IconButton, Tooltip } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';


class Posicao extends Component {

    componentDidMount() {
   
    }

    componentDidUpdate() {
        
    }


    render() {
        return (
            <div style={{marginLeft: "20px", marginTop: "15px"}}>
                <div style={{
                    display: 'flex',   
                    flexFlow: 'row wrap',                    
                    alignContent: "stretch",
                    width: "100%",
                    rowGap: "20px"                    
                }}>
                    <Card style={{backgroundColor: "#f9f9f9", flexGrow: "1", marginRight: "20px"}}>
                        <CardHeader title={"POSIÇÃO - " + this.props.ativoAtual} action={
                            <Tooltip title="Configurações" arrow>
                                <IconButton onClick={this.props.openSettings}>
                                    <SettingsIcon />
                                </IconButton>
                            </Tooltip>
                        }/>
                        <CardContent>
                            <p>Cotas Totais: {this.props.posicao?.cotas_totais}</p>
                            <p>Cotas Carteira: {this.props.posicao?.cotas_carteira}</p>
                        </CardContent>
                    </Card>
                    <Resultados sessionStore={this.props.sessionStore} 
                        resultados={this.props.resultados} ativoAtual={this.props.ativoAtual} atualizarAtivo={this.props.atualizarAtivo} />
                    <p>
                    </p>
                </div>
            </div>
        )
    }    

}

export default Posicao;
