import axios from "axios";
import session from "../Session"

var BASE_API_URL='https://api-gh.ortisdev.com';


const responseSuccessHandler = response => {
    return response;
};

const responseErrorHandler = error => {
    if (error.response.status === 401) {
        console.log("logout")
        session.clearStorage()
        window.location.reload(true);        
    }
    return Promise.reject(error);
};


    
var client = axios.create({
    baseURL: BASE_API_URL,
    timeout: 60000
});
export default class CurrentStateService {
    
        
    session = ''
    
    configAuth(sessionkey) {
        console.log(sessionkey)
        client = axios.create({
            baseURL: BASE_API_URL,
            timeout: 60000,
            headers: {
                'authorization' : sessionkey
            }
        });
        client.interceptors.response.use(
            response => responseSuccessHandler(response),
            error => responseErrorHandler(error)
            );
    }


    async login(user, passwd) {
        let credentials = btoa(user +':' + passwd, 'binary'); //.toString('base64');
        let response = await client.get('/login', {headers: {'authorization' : credentials }});
        if (response.status === 200) {
            let session = await response.data;
            return session;
        } else {
            return undefined;
        }

    }

    async getCurrentState(ativo) {
        const url = '/api/current-state/' + ativo
        const res = await client.get(url);
        console.log(res);
        return res.data;        
    }

    async getResultado(ativo) {
        const url = '/api/resultados/' + ativo
        const res = await client.get(url);
        console.log(res);
        return res.data;
    }

    async getAcoes(ativo) {
        const url = '/api/acoes/' + ativo
        const res = await client.get(url);
        console.log(res);
        return res.data;
    }

    async getGH(ativo) {
        const url = '/api/gh/' + ativo
        const res = await client.get(url);
        console.log(res);
        return res.data;
    }

    async getConfig(ativo, estrategia) {
        const url = '/api/config/' + ativo + "/" + estrategia
        const res = await client.get(url);
        console.log(res);
        return res.data;
    }

    async getConfigs() {
        const url = '/api/config/'
        const res = await client.get(url);
        console.log(res);
        return res.data;
    }

    async salvarConfig(ativo, estrategia, configs) {
        const url = '/api/config/' + ativo + "/" + estrategia
        configs.ativo = ativo
        configs.estrategia = estrategia
        const res = await client.post(url, configs);
        console.log(res);
        return res.data;
    }

    async newAcao(ativo, acao) {
        const data = {
            "ativo":ativo,
            "acao": acao,
            "data": new Date().toISOString(),
            "executada": false
        }
        const url = '/api/acoes/' + ativo
        const res = await client.post(url, data);
        console.log(res);
        return res.data;
    }


    async atualizarGh(ativo, gh) {
        //gh.ativo = ativo;
        console.log("irá enviar " + JSON.stringify(gh))
        const url = '/api/gh/' + ativo + '?valor=' + gh.valor;
        const res = await client.post(url, gh);
        console.log(res);
        return res.data;
    }

    async excluirGh(ativo, valor) {
        const data = {
            "ativo": ativo,
            "valor": valor
        }
        const url = '/api/gh/' + ativo+ '?valor=' + valor;;
        const res = await client.delete(url, data);
        console.log(res);
        return res.data;
    }

    async mt5Status() {
        const url = '/api/status'
        const res = await client.get(url);
        console.log(res);
        var estado = {}
        res.data.forEach(stat => {
            estado[stat.property] = stat.value            
        });
        console.log(estado)
        return estado;
    }

    async adicionarAtivo(ativo, estrategia) {
        const url = '/api/config/' + ativo + "/" + estrategia        
        const configs = {            
                ativo: "",
                caixa: 0,
                estrategia: "",
                fim: 1900,
                inicio: 800,
                step: 0,
                stopGain: -1,
                stopLoss: -1
        }
        configs.ativo = ativo
        configs.estrategia = estrategia
        const res = await client.post(url, configs);
        console.log(res);
        return res.data;    
    }

}