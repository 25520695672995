import React, { Component } from 'react';
import { Box, Button, Card, CardContent, CardHeader, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { Typography } from '@material-ui/core';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Tooltip, Dialog, DialogTitle, DialogContent, DialogActions} from '@mui/material';
import { observer } from "mobx-react";
import { action, observable, makeObservable } from "mobx";
import CurrentStateService from '../service/CurrentStateService';

interface IAtivosProps {
  sessionStore?: any;
  ativos?: any;  
  atualizarAtivoAtual?: any;
}

interface IAtivosState {
}

@observer
class Ativos extends Component<IAtivosProps, IAtivosState> {

  service = new CurrentStateService();

  @observable ativos = []
  @observable aguardandoAcao = false
  @observable dialogAtivoOpen = false  
  @observable novoAtivo = ""
  @observable novoEstrategia = "gh"

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  @action
  mudarDeAtivo(novo) {
    this.props.atualizarAtivoAtual(novo);
  }

  @action
  async adicionarAtivo() {
    //TODO - validar
    console.log("Adicionando ", this.novoAtivo.toUpperCase())
    if (this.ativos.indexOf(this.novoAtivo.toUpperCase()) == -1) {
      await this.service.adicionarAtivo(this.novoAtivo.toUpperCase(), this.novoEstrategia)
    }
    this.props.atualizarAtivoAtual(this.novoAtivo.toUpperCase());
    this.dialogAtivoOpen = false;
  }

  @action
  closeDialogGh() {
    this.dialogAtivoOpen = false;
  }

  @action
  openDialogGh() {    
    this.dialogAtivoOpen = true;
  }

  @action
  handleAtivoChange = (event: any) => {
    if (event.target.value != undefined && event.target.value != ""){
      this.novoAtivo = event.target.value;
      console.log("setando ", this.novoAtivo)
    }

    // validacoes
  };
  

  @action
  componentDidMount() {    
  }
  
  render() {
    return (
      <Card style={{backgroundColor: "#f9f9f9", flexGrow: 4, marginRight: "20px", marginLeft: "20px"}}>
          <CardContent style={{display: "flex", flexDirection:"row"}}> 
            <div >
              <Tooltip title="Adicionar" arrow>
               <IconButton onClick={this.openDialogGh.bind(this)}>
                  <AddCircleIcon  />
               </IconButton>
              </Tooltip>
            </div>
            {this.props.ativos ? 
              <Box style={{display: "flex", flexGrow: "3", gap: "15px", marginLeft:"50px"}}>
                {this.props.ativos.map((row) => (
                  <Button variant="outlined" color='primary' id={'btn' + row.ativo} onClick={this.mudarDeAtivo.bind(this, row.ativo)}>{row.ativo}</Button>
                ))}
              </Box>
              :<Box></Box>
            }
            {this.ghDialog()}
          </CardContent>
      </Card>
    )
  }


  ghDialog() {
    return (
          <Dialog open={this.dialogAtivoOpen} maxWidth='md'>
                <DialogTitle>
                    Adicionar Novo Robô
                </DialogTitle>
                <DialogContent>
                <div style={{display: 'flex',   
                    flexFlow: 'row wrap',                    
                    margin: 5,                    
                    gap: "20px"}} >                      
                      <TextField
                        id="ativo"
                        label="Código do Ativo"
                        value={this.novoAtivo}
                        onChange={(e) => this.handleAtivoChange(e)}
                      />
                      <TextField
                        id="estrategia"
                        label="Estratégia"
                        disabled={true}
                        value={this.novoEstrategia}                        
                      />                      
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button variant='contained' color='primary' onClick={this.adicionarAtivo.bind(this)}>
                        Adicionar
                    </Button>
                    <Button variant='contained' color='secondary' onClick={this.closeDialogGh.bind(this)}>
                        Cancelar
                    </Button>
                </DialogActions>
            </Dialog>
          )
  }
}

export default Ativos;
