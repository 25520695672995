import React, { useState, useEffect, Component } from "react";
//import './index.css';
import { observer } from "mobx-react";
import { action, observable, makeObservable } from "mobx";
import { Typography } from "@material-ui/core";
import Posicao from "./pages/posicao";
import Trades from "./pages/trades";
import Captura from "./pages/captura";
import CurrentStateService from "./service/CurrentStateService";
import Ativos from "./pages/ativos";
import RefreshIcon from '@mui/icons-material/Refresh';
import Tooltip from '@mui/material/Tooltip';

interface IMainProps {
  sessionStore?: any;
  atualizarAtivoAtualSettings?: any;
  openSettings?: any;
}

interface IMainState {
}

@observer
class Main extends Component<IMainProps, IMainState> {

  @observable ativos = []
  @observable posicao = {}
  @observable trades = {}
  @observable candles = {}
  @observable resultados = []
  @observable aguardandoRefresh = false
  @observable ativoAtual = 'HASH11'
  service = new CurrentStateService();

  @action
  componentDidMount() {
    this.update()
  }

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  @action
  atualizarAtivoAtual(novo) {
    this.ativoAtual = novo;
    this.props.atualizarAtivoAtualSettings(novo);
    this.update();
  }

  @action
  update() {
    if (this.props.sessionStore.sessionkey !== '') {
      this.aguardandoRefresh = true
      this.service.configAuth(this.props.sessionStore.sessionkey);
      this.service.getCurrentState(this.ativoAtual).then((res) => {
        this.posicao = res.posicao;
        this.trades = res.trades;
        this.candles = res.candles;
        this.aguardandoRefresh = false
      }).catch((e) => {
        console.log(e);
      })
      this.service.getResultado(this.ativoAtual).then((res) => {
        this.resultados = res;
      }).catch((e2) => {
        console.log(e2);
      })
      this.service.getConfigs().then((res) => {
        this.ativos = res;
      }).catch((e3) => {
        console.log(e3);
      })
    }
  }

  render() {
    return (
      <div>
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
          <Typography style={{ margin: 10, maxWidth: '75%', fontSize: 20 }}>
            ACOMPAMAMENTO DAS OPERAÇÕES - {this.ativoAtual}
          </Typography>
          <Tooltip title="Atualizar Tela">
            {this.aguardandoRefresh ? <RefreshIcon color="disabled" /> : <RefreshIcon onClick={this.update.bind(this)} />}
          </Tooltip>
        </div>
        <Ativos sessionStore={this.props.sessionStore} ativos={this.ativos} atualizarAtivoAtual={this.atualizarAtivoAtual.bind(this)}/>
        <Posicao posicao={this.posicao} sessionStore={this.props.sessionStore} resultados={this.resultados} ativoAtual={this.ativoAtual} atualizarAtivo={this.atualizarAtivoAtual.bind(this)} openSettings={this.props.openSettings} />
        <Trades trades={this.trades} ativoAtual={this.ativoAtual} />
        <Captura posicao={this.posicao} sessionStore={this.props.sessionStore} />
      </div>
    )
  }
}

export default Main;

