import React, { Component } from 'react';
import { Card, CardContent, CardHeader, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { observer } from "mobx-react";
import { action, observable, makeObservable, computed } from "mobx";
import CurrentStateService from '../service/CurrentStateService';
import Button from '@mui/material/Button';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Tooltip, Dialog, DialogTitle, DialogContent, DialogActions} from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import { MobileTimePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import Typography from '@mui/material/Typography';

interface ISettingsProps {
  sessionStore?: any;
  close?: any;
  ativoAtual?: any;
}

interface ISettingsState {
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

@observer
class Settings extends Component<ISettingsProps, ISettingsState> {

  service = new CurrentStateService();

  @observable currentTab = 0;

  //@observable ativo = 'HASH11'

  @observable inicio = {}

  @observable fim = {}

  @observable settingsValues?:any = {
    ativo: "Carregando",
    caixa: 0,
    estrategia: "Carregando",
    fim: 1900,
    inicio: 800,
    step: 0,
    stopGain: -1,
    stopLoss: -1
  };

  @observable newGhValue?:any = {
    ativo: "Carregando",
    valor: "0.0", 
    lote_entrada: "0",
    lote_saida: "0",
    preco_saida: "0.0",
  };
  
  @observable gradiente = []
  @observable aguardandoAcao = false  
  @observable dialogGhOpen = false
  @observable edit = false

  timer? = undefined

  constructor(props) {
    super(props);
    makeObservable(this);
    TabPanel.bind(this)
  }

  @action
  handleChange = (event: React.SyntheticEvent, newValue: number) => {
    this.currentTab = newValue;
  };

  @action
  handleConfigChange = (event: any, campo: string) => {
    console.log(event);
    if (event.target.value != undefined && event.target.value != "")
      this.settingsValues[campo] = event.target.value;
  };

  @action
  handleTimeChange = (event: any, campo: string) => {
    console.log(event);
    this.settingsValues[campo] = event.format("HHmm")
    if (campo == "inicio")
      this.inicio = event
      if (campo == "fim")
      this.fim = event
  };

  @action
  handleGHChange = (event: any, campo: string) => {
    if (event.target.value != undefined && event.target.value != "")
      this.newGhValue[campo] = event.target.value;
    // validacoes
    if (this.newGhValue.lote_entrada < this.newGhValue.lote_saida)
      console.log("Tamanho dos lotes errado");

    if (this.newGhValue.valor < this.newGhValue.preco_saida)
      console.log("valor de saida errado")
  };

  @action
  atualizarConfigs = async () => {
    this.service.configAuth(this.props.sessionStore.sessionkey);
    try {
      this.aguardandoAcao = true
      var resp = await this.service.getConfig(this.props.ativoAtual, "gh");
      this.settingsValues = resp[0];
      this.settingsValues.caixa = this.formatarNumero(this.settingsValues.caixa)
      this.settingsValues.stopGain = this.formatarNumero(this.settingsValues.stopGain)
      this.settingsValues.stopLoss = this.formatarNumero(this.settingsValues.stopLoss)
      this.settingsValues.step = this.formatarNumero(this.settingsValues.step)
      this.inicio = this.strHora(this.settingsValues.inicio)
      this.fim = this.strHora(this.settingsValues.fim)
      this.aguardandoAcao = false
      this.forceUpdate();
    } catch(e) {
      this.aguardandoAcao = false
      console.log(e);
    }
  }

  @action
  salvarConfigs = async () => {
    this.aguardandoAcao = true
    this.service.configAuth(this.props.sessionStore.sessionkey);
    try {
      this.settingsValues.caixa = this.strFloat(this.settingsValues.caixa)
      this.settingsValues.stopGain = this.strFloat(this.settingsValues.stopGain)
      this.settingsValues.stopLoss = this.strFloat(this.settingsValues.stopLoss)
      this.settingsValues.step = this.strFloat(this.settingsValues.step)
      //this.settingsValues.inicio = this.numberHora(this.inicio)
      //this.settingsValues.fim = this.numberHora(this.fim.format("HHmm"))
      await this.service.salvarConfig(this.props.ativoAtual, "gh", this.settingsValues);
      this.atualizarConfigs();
    } catch(e) {
      this.settingsValues.caixa = this.formatarNumero(this.settingsValues.caixa)
      this.settingsValues.stopGain = this.formatarNumero(this.settingsValues.stopGain)
      this.settingsValues.stopLoss = this.formatarNumero(this.settingsValues.stopLoss)
      this.settingsValues.step = this.formatarNumero(this.settingsValues.step)
      this.inicio = this.strHora(this.settingsValues.inicio)
      this.fim = this.strHora(this.settingsValues.fim)
      this.aguardandoAcao = false
      console.log(e);
    }
  }  

  @action
  atualizarGradiente = async () => {
    this.service.configAuth(this.props.sessionStore.sessionkey);
    try {
      var resp = await this.service.getGH(this.props.ativoAtual);
      this.gradiente = resp;
      this.gradiente.forEach(g => {
        g.valor = this.formatarNumero(g.valor.toString())
        g.preco_saida = this.formatarNumero(g.preco_saida.toString())
      });
      this.forceUpdate();
    } catch(e) {
      console.log(e);
    }
  }

  @action
  componentDidMount() {
    // buscar Settings     
    this.atualizarConfigs();
    this.atualizarGradiente();
  }

  @action
  closeSettings() {
    this.props.close();
  }

  @action
  closeDialogGh() {
    this.dialogGhOpen = false;
  }

  @action
  openDialogGh() {
    this.edit = false;
    this.dialogGhOpen = true;
  }

  @action
  editar(ativo, valor) {
    this.gradiente.forEach(g => {
      if (g.valor == valor) {
        this.newGhValue.ativo = ativo;
        this.newGhValue.valor = g.valor;
        this.newGhValue.lote_entrada = g.lote_entrada;
        this.newGhValue.lote_saida = g.lote_saida;
        this.newGhValue.preco_saida = g.preco_saida;
        this.edit = true
        this.dialogGhOpen = true
      }
    });    
  }

  @action
  async excluir(ativo, valor) {
    try {
      await this.service.excluirGh(ativo, valor);
      this.atualizarGradiente();
    } catch(e) {
      console.log(e);
    }
  }


  @action
  adicionarGh = async () => {
    try {      
      this.newGhValue.ativo = this.props.ativoAtual;
      this.newGhValue.valor = this.strFloat(this.newGhValue.valor);
      this.newGhValue.preco_saida = this.strFloat(this.newGhValue.preco_saida);      
      await this.service.atualizarGh(this.props.ativoAtual, this.newGhValue);
      this.dialogGhOpen = false;
      this.atualizarGradiente();      
    } catch(e) {
      console.log(e)
    }
  }

  formatarNumero(valor) {
    if (valor == undefined)
      valor = 0.0;
    //console.log("Antes " + valor)
    var v = parseFloat(valor).toFixed(2);
    //console.log("Depois " + v.replace(".", ","))
    return v.replace(".", ",")
  }

  strFloat(valor) {
    if (valor == undefined)
      valor = "0,0";
    return parseFloat(valor.toString().replace(",", "."))
  }

  strHora(valor) {
    const pair = Array.from(valor.toString())    
    pair.splice(-2, 0, ':')
    console.log(moment(pair.join(''), 'LT'))
    return moment(pair.join(''), 'LT')

  }

  numberHora(valor) {    
    return valor.replace(':','');
  }
  
  
  calculaCusto(valor, cota) {
    return this.strFloat(valor) * this.strFloat(cota)
  }

 
  render() {
    return (
      
      <Card style={{flexGrow: 1}}>          
          <CardContent style={{display: "flex", flexFlow: "column"}}>
            <div style={{display: "flex", gap: "10px", flexWrap: "wrap"}}>
              <Typography variant="h5" gutterBottom component="div" style={{marginRight: "200px"}}>CONFIGURAÇÕES - {this.props.ativoAtual}</Typography>              
              <Button disabled={this.aguardandoAcao} variant="contained" color="primary" onClick={this.salvarConfigs.bind(this)}>Salvar</Button>
              <Button variant="contained" color="secondary" onClick={this.closeSettings.bind(this)}>Fechar</Button>
            </div>
            <Box sx={{ width: '100%' }}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={this.currentTab} onChange={this.handleChange.bind(this)} aria-label="basic tabs example">
                  <Tab label="Parâmetros Gerais" {...a11yProps(0)} />
                  <Tab label="Gradiente Harmônico" {...a11yProps(1)} />                  
                </Tabs>
              </Box>
              <TabPanel value={this.currentTab} index={0}>                
                <Card style={{flexGrow: 1}}>
                  <CardContent>                    
                    <div style={{display: 'flex',   
                    flexFlow: 'row wrap',                    
                    margin: 5,                    
                    gap: "20px"}} >                      
                      <TextField
                        id="estrategia"
                        label="Estrategia"
                        disabled
                        value={this.settingsValues?.estrategia}
                        onChange={(e) => this.handleConfigChange(e, "estrategia")}
                      />
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                          <MobileTimePicker                        
                            label="Hora do Início da Estratégia"                        
                            ampm={false}
                            disabled={this.aguardandoAcao}
                            value={this.inicio}
                            renderInput={(params) => <TextField {...params} />}
                            onChange={(e) => this.handleTimeChange(e, "inicio")}
                          />
                          <MobileTimePicker                        
                            label="Hora do Fim da Estratégia"                       
                            ampm={false}
                            value={this.fim}
                            disabled={this.aguardandoAcao}
                            renderInput={(params) => <TextField {...params} />}
                            onChange={(e) => this.handleTimeChange(e, "fim")}
                            />
                      </LocalizationProvider>
                      <TextField
                        id="caixa"
                        label="Tamanho da Caixa (Valor máximo que o ativo pode alocar)"                      
                        value={this.settingsValues?.caixa}
                        disabled={this.aguardandoAcao}
                        InputProps={{
                          startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                        }}
                        onChange={(e) => this.handleConfigChange(e, "caixa")}
                      />
                      <TextField
                        id="stopGain"
                        label="Stop Gain"
                        value={this.settingsValues?.stopGain}
                        disabled={this.aguardandoAcao}
                        InputProps={{
                          startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                        }}
                        onChange={(e) => this.handleConfigChange(e, "stopGain")}
                        />
                      <TextField
                        id="stopLoss"
                        label="Stop Loss"
                        value={this.settingsValues?.stopLoss}
                        disabled={this.aguardandoAcao}
                        InputProps={{
                          startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                        }}
                        onChange={(e) => this.handleConfigChange(e, "stopLoss")}
                        />
                      <TextField
                        id="step"
                        label="Step (tamanho de cada entrada)"
                        value={this.settingsValues?.step}
                        disabled={this.aguardandoAcao}
                        InputProps={{
                          startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                        }}
                        onChange={(e) => this.handleConfigChange(e, "step")}
                        />  
                    </div>                    
                  </CardContent>
                </Card>
              </TabPanel>
              <TabPanel value={this.currentTab} index={1}>
                <div style={{display: "flex", justifyContent:"right"}}>
                <Button variant="contained" color="secondary" onClick={this.openDialogGh.bind(this)}>Adicionar Entrada</Button>
                </div>
                <TableContainer component={Paper}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">Valor Entrada</TableCell>              
                        <TableCell align="center">Lote Entrada</TableCell>
                        <TableCell align="center">Custo Entrada</TableCell>
                        <TableCell align="center">Preço Saída</TableCell>
                        <TableCell align="center">Lote Saída</TableCell>
                        <TableCell align="center">Retorno Venda</TableCell>
                        <TableCell align="center">Ação</TableCell>
                      </TableRow>
                    </TableHead>
                    {this.gradiente && this.gradiente.length > 0 ?
                      <TableBody>
                        {this.gradiente.map((row) => (
                          <TableRow
                            key={row.ativo+"_"+row.valor}
                          >                            
                            <TableCell align="center">R$ {row.valor}</TableCell>
                            <TableCell align="center">{row.lote_entrada} cotas</TableCell>
                            <TableCell align="center">R$ {this.calculaCusto(row.valor,row.lote_entrada)}</TableCell>
                            <TableCell align="center">R$ {row.preco_saida}</TableCell>
                            <TableCell align="center">{row.lote_saida} cotas</TableCell>
                            <TableCell align="center">R$ {this.calculaCusto(row.preco_saida,row.lote_saida)}</TableCell>
                            <TableCell align="center" style={{gap: '20px', flexFlow: 'row', flexWrap: 'wrap',display: 'flex', justifyContent:"center"}}>
                              <Tooltip title="Editar" arrow>
                                <EditIcon onClick={this.editar.bind(this, row.ativo, row.valor)} />
                              </Tooltip>
                              <Tooltip title="Excluir" arrow>                                
                                <DeleteForeverIcon onClick={this.excluir.bind(this, row.ativo, row.valor)} />
                              </Tooltip>                              
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                      :
                      <TableBody>
                        <TableRow
                        >                          
                          <TableCell align="center"></TableCell>
                          <TableCell align="center"></TableCell>
                          <TableCell align="center"></TableCell>
                          <TableCell align="center"></TableCell>
                          <TableCell align="center"></TableCell>
                          <TableCell align="center"></TableCell>                                                                        
                        </TableRow>
                      </TableBody>
                    }
                  </Table>
                </TableContainer>
                {this.ghDialog()}
              </TabPanel>              
            </Box>            
          </CardContent>
      </Card>
    )
  }

  ghDialog() {
    return (
          <Dialog open={this.dialogGhOpen} maxWidth='md'>
                <DialogTitle>
                    Adicionar entrada no Gradiente Harmônico
                </DialogTitle>
                <DialogContent>
                <div style={{display: 'flex',   
                    flexFlow: 'row wrap',                    
                    margin: 5,                    
                    gap: "20px"}} >                      
                      <TextField
                        id="estrategia"
                        label="Valor Entrada"
                        value={this.newGhValue.valor}
                        InputProps={{
                          startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                        }}
                        onChange={(e) => this.handleGHChange(e, "valor")}
                      />
                      <TextField
                        id="inicio"
                        label="Lote na entrada"
                        value={this.newGhValue.lote_entrada}
                        InputProps={{
                          endAdornment: <InputAdornment position="start">cotas</InputAdornment>,
                        }}
                        onChange={(e) => this.handleGHChange(e, "lote_entrada")}
                      />
                      <TextField
                        id="inicio"
                        label="Custo na entrada"
                        disabled={true}
                        value={this.calculaCusto(this.newGhValue.valor, this.newGhValue.lote_entrada)}
                        InputProps={{
                          startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                        }}                       
                      />
                      <TextField
                        id="caixa"
                        label="Preço de Saída"
                        value={this.newGhValue.preco_saida}
                        InputProps={{
                          startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                        }}
                        onChange={(e) => this.handleGHChange(e, "preco_saida")}
                      />                                            
                      <TextField
                        id="fim"
                        label="Tamanho do lote na saída"
                        value={this.newGhValue.lote_saida}
                        InputProps={{
                          endAdornment: <InputAdornment position="start">cotas</InputAdornment>,
                        }}
                        onChange={(e) => this.handleGHChange(e, "lote_saida")}
                        />
                        <TextField
                        id="caixa"
                        label="Retorno de Saída"
                        disabled={true}                        
                        value={this.calculaCusto(this.newGhValue.preco_saida, this.newGhValue.lote_saida)}
                        InputProps={{
                          startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                        }}
                        
                      />
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button variant='contained' color='primary' onClick={this.adicionarGh.bind(this)}>
                        {this.edit ? "Atualizar": "Adicionar"}
                    </Button>
                    <Button variant='contained' color='secondary' onClick={this.closeDialogGh.bind(this)}>
                        Cancelar
                    </Button>
                </DialogActions>
            </Dialog>
          )
  }


}

export default Settings;
